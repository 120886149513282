import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Data Collection App"
              description="Developed a mobile data collection app, targeting iOS/Android platforms. The app boasts an intuitive UI and secure data transmission via APIs. Technologies used include Flutter, RESTful API, and OpenLayers."
              //ghLink="https://github.com/soumyajit4419/Chatify"
              //demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Agricultural Decision Support System"
              description="Developed a decision support system for the agricultural sector, enhancing farm management and productivity. The system includes user and resource management modules, and offers features like custom PDF report generation and email dispatch. It utilizes Java, Spring Boot, and PostgreSQL, along with GeoServer for spatial data handling."
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Alfresco-Based Document Management System (GED)"
              description="Implemented an Alfresco-based Electronic Document Management System (GED), focusing on optimizing document storage and retrieval processes. Customized Alfresco's user interface to meet specific client requirements and integrated the system for efficient document handling. Technologies used include Java, Angular, and PostgreSQL for robust and scalable document management solutions."              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Web Agenda Application for Task Management"
              description="Designed and developed a web-based agenda application, aimed at enhancing task management and scheduling for company personnel. The application features customizable planning tools, task creation, modification, and deletion functionalities, along with assignment capabilities. Integrated email notification system (SMTP) to keep employees updated. Developed with Java, JSP, JavaScript, and PostgreSQL, ensuring a user-friendly and efficient organizational tool."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Climate Data Extraction Application"
              description="Engineered a sophisticated application for the automatic extraction of climate data, streamlining the process of data gathering and analysis. The application features user-friendly interfaces for downloading climate data, and manages a wide range of climatological information. Developed using Angular, Spring Boot, PostgreSQL, and Python, with REST API integration, this tool efficiently handles diverse data sources, making climate data more accessible and actionable."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Server Installation and Security Enhancement"
              description="Executed a comprehensive project for installing, configuring, and securing web servers on Linux systems. The project involved setting up Apache and Tomcat servers, integrating SSL certificates for enhanced security, and creating detailed documentation for system configurations. This initiative significantly improved server reliability and data protection, showcasing my skills in server management and cybersecurity."
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
