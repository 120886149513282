import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function calculateAge(birthDate) {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  return age;
}

function calculateExperience(startDate) {
  const today = new Date();
  const start = new Date(startDate);
  let years = today.getFullYear() - start.getFullYear();
  const monthDiff = today.getMonth() - start.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < start.getDate())) {
    years--;
  }
  return years;
}

function AboutCard() {
  const age = calculateAge("1997-09-01");
  const experience = calculateExperience("2021-03-01");

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Raed Madouri </span>
            from <span className="purple"> Tunis, Tunisia.</span>
            <br />A {age}-year-old, dynamic, and passionate computer engineer with {experience} years of experience in 
            software development. Possesses a strong background in designing, developing, and implementing 
            efficient and innovative IT solutions. Skilled at working in teams and solving complex problems. 
            An exemplary communicator with a results-oriented and customer satisfaction-focused approach.
            <br />
            Additionally, I am currently employed as a software developer at
            Yebni Information & Communication.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Raed</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
